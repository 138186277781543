<template>
  <div id="pageInconnue">

      <bloc-header2 page="Page inconnue" />

    <div id="container-pageInconnue" class="containerAffichage">

        <div class="pageInconnue">
            <a href="/" class="" title="Accueil Lorfolio">
                <img src="../assets/img/page-inconnue.png" alt="page inconnue" />
            </a>
        </div>
    </div>

    <bloc-footer/>
  </div>
</template>


<script>
import Header2 from '@/components/Header2.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'MentionsLegales',
    data() {
        return {
            tmp:true,
        }
    },
    components: {
        'bloc-header2': Header2,
        'bloc-footer': Footer,
    },
    beforeCreate() {
        this.$parent.sitePRO = true;
    },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #pageInconnue {
        text-align: center;
        img {
            max-width: 90%;
            height:auto;
            margin:0 auto;
        }
    }
</style>
